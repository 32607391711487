<template>
  <div class="page">
    <div class="page-left">
      <div class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened>
            <el-submenu index="znsy">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">智能输液</span>
              </template>
              <el-menu-item index="/smartbeds/intelligentinfusion/databoard" @click="saveNavState('/smartbeds/intelligentinfusion/databoard')">
                <template slot="title">
                  <span class="mrgin-left">数据看板</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/intelligentinfusion/devicerun" @click="saveNavState('/smartbeds/intelligentinfusion/devicerun')">
                <template slot="title">
                  <span class="mrgin-left">设备管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/intelligentinfusion/personnelrun" @click="saveNavState('/smartbeds/intelligentinfusion/personnelrun')">
                <template slot="title">
                  <span class="mrgin-left">人员管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/intelligentinfusion/earlywarnrun" @click="saveNavState('/smartbeds/intelligentinfusion/earlywarnrun')">
                <template slot="title">
                  <span class="mrgin-left">预警管理</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="hsz">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">护士站</span>
              </template>
              <el-menu-item index="/smartbeds/nursestation/patientlist" @click="saveNavState('/smartbeds/nursestation/patientlist')">
                <template slot="title">
                  <span class="mrgin-left">病人列表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/nursestation/nurstasks" @click="saveNavState('/smartbeds/nursestation/nurstasks')">
                <template slot="title">
                  <span class="mrgin-left">护理任务</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/nursestation/handoverreport" @click="saveNavState('/smartbeds/nursestation/handoverreport')">
                <template slot="title">
                  <span class="mrgin-left">交班报告</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/nursestation/nursinspectionform" @click="saveNavState('/smartbeds/nursestation/nursinspectionform')">
                <template slot="title">
                  <span class="mrgin-left">护理巡视单</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/nursestation/dischargedpatient" @click="saveNavState('/smartbeds/nursestation/dischargedpatient')">
                <template slot="title">
                  <span class="mrgin-left">出院病人查询</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="yz">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">医嘱</span>
              </template>
              <el-menu-item index="/smartbeds/doctorsadvice/doctorsadvicelist" @click="saveNavState('/smartbeds/doctorsadvice/doctorsadvicelist')">
                <template slot="title">
                  <span class="mrgin-left">医嘱列表</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/doctorsadvice/executiondetails" @click="saveNavState('/smartbeds/doctorsadvice/executiondetails')">
                <template slot="title">
                  <span class="mrgin-left">执行明细</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="hlws">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">护理文书</span>
              </template>
              <el-menu-item index="/smartbeds/nursdocument/record" @click="saveNavState('/smartbeds/nursdocument/record')">
                <template slot="title">
                  <span class="mrgin-left">出入量记录单</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/nursdocument/estimate" @click="saveNavState('/smartbeds/nursdocument/estimate')">
                <template slot="title">
                  <span class="mrgin-left">评估记录单</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="hlpf">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">护理评分</span>
              </template>
              <el-menu-item index="/smartbeds/nursscore/braden" @click="saveNavState('/smartbeds/nursscore/braden')">
                <template slot="title">
                  <span class="mrgin-left">Braden评分</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/nursscore/riskfactor" @click="saveNavState('/smartbeds/nursscore/riskfactor')">
                <template slot="title">
                  <span class="mrgin-left">坠床/跌倒危险因素评分</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/nursscore/gcs" @click="saveNavState('/smartbeds/nursscore/gcs')">
                <template slot="title">
                  <span class="mrgin-left">格拉斯哥(GCS)昏迷评分</span>
                </template>
              </el-menu-item>
            </el-submenu>

            <el-submenu index="xtgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">系统(病区)设置</span>
              </template>
              <el-menu-item index="/smartbeds/wardset/userrun" @click="saveNavState('/smartbeds/wardset/userrun')">
                <template slot="title">
                  <span class="mrgin-left">用户管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/wardset/documenttemplate" @click="saveNavState('/smartbeds/wardset/documenttemplate')">
                <template slot="title">
                  <span class="mrgin-left">文书模板维护</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/wardset/documentmenu" @click="saveNavState('/smartbeds/wardset/documentmenu')">
                <template slot="title">
                  <span class="mrgin-left">文书菜单配置</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smartbeds/wardset/custom" @click="saveNavState('/smartbeds/wardset/custom')">
                <template slot="title">
                  <span class="mrgin-left">自定义体征项维护</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      activePath: '/smartbeds'
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('smartbedsPath') || '/smartbeds'
  },
  methods: {
    saveNavState (path) {
      window.sessionStorage.setItem('smartbedsPath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    }
  }
}
</script>

<style scoped lang="less">
.page {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #F0F0F0;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    width: 276px;
    height: 100%;
    background-color: #006EFF;

    .menu {
      width: 100%;
      height: 100%;
    }

    .scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 10px);

      &::-webkit-scrollbar {
        width: 0;
      }

      i {
        color: #fff;
      }

      .el-menu {
        border: none;
        background-color: #006EFF !important;
        color: #fff !important;
      }

      /deep/.el-menu-item {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 !important;
        background-color: #006EFF !important;
        font-size: 16px;
        color: #fff !important;
      }

      /deep/.el-menu-item.is-active {
        color: #25A0FF !important;
      }

      .font {
        color: #fff;
        font-size: 16px;
      }

      // /deep/.el-menu-item:nth-child(n+3) {
      //   height: 100px;
      // }

      // /deep/.el-menu-item:nth-child(7) {
      //   height: 50px;
      // }

      // /deep/.el-submenu:nth-child(3) {
      //   .el-menu-item {
      //     height: 50px !important;
      //   }

      // }

      // /deep/.el-submenu:nth-child(6) {
      //   .el-menu-item {
      //     height: 50px !important;
      //   }

      // }

      /deep/.el-submenu__title {
        display: flex;
        align-items: center;
        height: 45px;
        padding: 1px !important;
        padding-right: 2px !important;
        background-color: #006EFF !important;
        color: #17212B !important;
      }

      /deep/.el-icon-arrow-down:before {
        content: '';
      }

      .menu-text {
        // width: calc(100% - 40px);
        line-height: normal;
        white-space: pre-wrap;
      }

      .submenu-text {
        // width: calc(100% - 30px);
        line-height: normal;
        white-space: pre-wrap;
      }

      .mrgin-left {
        display: block;
        // width: 80px;
        margin-left: 30px;
        line-height: normal;
        white-space: pre-wrap;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 276px);
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .after {
    position: absolute;
    bottom: 50%;
    right: 40px;
    z-index: 7;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 50%;
    background-color: #3FA1FF;
    color: #DCE4EC;
    font-size: 18px;
    cursor: pointer;
  }
}</style>
