<template>
  <div class="page">
    <div class="page-left">
      <div v-if="isCollapse" class="collapse">
        <div class="top" @click="isCollapse = !isCollapse">
          <span>展开</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="bot">
          <i class="el-icon-caret-top" @click="pre"></i>
          <i class="el-icon-refresh-right" @click="refresh"></i>
          <i class="el-icon-caret-bottom" @click="next"></i>
        </div>
      </div>
      <div v-if="!isCollapse" class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened @open="handleOpen">
            <el-menu-item index="/smartconsultation" @click="saveNavState('/smartconsultation')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>智慧问诊</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smarthealthcare/drugrun" @click="saveNavState('/smarthealthcare/drugrun')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">药品管理</span>
              </template>
            </el-menu-item>
            <!-- <el-submenu index="ypgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">药品管理</span>
              </template>
              <el-menu-item index="/smarthealthcare/drugrun" @click="saveNavState('/smarthealthcare/drugrun')">
                <template slot="title">
                  <span class="submenu-text">药品管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/diseaserun" @click="saveNavState('/smarthealthcare/diseaserun')">
                <template slot="title">
                  <span class="submenu-text">疾病管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/reciperun" @click="saveNavState('/smarthealthcare/reciperun')">
                <template slot="title">
                  <span class="submenu-text">处方管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/roomrun" @click="saveNavState('/smarthealthcare/roomrun')">
                <template slot="title">
                  <span class="submenu-text">科室管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/doctorrun" @click="saveNavState('/smarthealthcare/doctorrun')">
                <template slot="title">
                  <span class="submenu-text">医生管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/hospitalrun" @click="saveNavState('/smarthealthcare/hospitalrun')">
                <template slot="title">
                  <span class="submenu-text">医院管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/listrun" @click="saveNavState('/smarthealthcare/listrun')">
                <template slot="title">
                  <span class="submenu-text">订单管理</span>
                </template>
              </el-menu-item>
            </el-submenu> -->
            <el-menu-item index="/smarthealthcare/diseaseruns" @click="saveNavState('/smarthealthcare/diseaseruns')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">疾病管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smarthealthcare/reciperuns" @click="saveNavState('/smarthealthcare/reciperuns')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">处方管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smarthealthcare/roomruns" @click="saveNavState('/smarthealthcare/roomruns')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">科室管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smarthealthcare/doctorruns" @click="saveNavState('/smarthealthcare/doctorruns')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">医生管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/smarthealthcare/hospitalruns" @click="saveNavState('/smarthealthcare/hospitalruns')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">医院管理</span>
              </template>
            </el-menu-item>
            <el-submenu index="/ddgl">
              <template slot="title" @click="saveNavState('/smarthealthcare/diseaseruns')">
                <i class="el-icon-menu"></i>
                <span class="font">订单管理</span>
              </template>
              <el-menu-item index="/404">
                <template slot="title">
                  <span class="submenu-text">门诊购药</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/orderrun/requirementupload" @click="saveNavState('/smarthealthcare/orderrun/requirementupload')">
                <template slot="title">
                  <span class="submenu-text">群众购药需求上报</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/lbgl">
              <template slot="title" @click="saveNavState('/smarthealthcare/twoDiseaseManagement')">
                <i class="el-icon-menu"></i>
                <span class="font" :class="{ activeLbglColor: activeLbgl }">两病管理</span>
              </template>
              <el-menu-item index="/smarthealthcare/applicationapprovalmanagement" @click="saveNavState('/smarthealthcare/applicationapprovalmanagement')">
                <template slot="title">
                  <span class="submenu-text">申报审批管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/benefitsQualificationManagement" @click="saveNavState('/smarthealthcare/benefitsQualificationManagement')">
                <template slot="title">
                  <span class="submenu-text">待遇资质管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/systemRun" @click="saveNavState('/smarthealthcare/systemRun')">
                <template slot="title">
                  <span class="submenu-text">系统管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="/ycyl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">远程医疗</span>
              </template>
              <el-menu-item index="/bultrasonic" @click="saveNavState('/bultrasonic')">
                <template slot="title">
                  <span class="submenu-text">远程B超</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/ecg" @click="saveNavState('/ecg')">
                <template slot="title">
                  <span class="submenu-text">远程心电</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/image" @click="saveNavState('/image')">
                <template slot="title">
                  <span class="submenu-text">远程影像</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/consultation" @click="saveNavState('/consultation')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>会诊</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/referral" @click="saveNavState('/referral')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>转诊</span>
              </template>
            </el-menu-item>
            <el-submenu index="/hlwyy">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text font">互联网医院</span>
              </template>
              <el-menu-item index="/smarthealthcare/consultationManagement" @click="saveNavState('/smarthealthcare/consultationManagement')">
                <template slot="title">
                  <span class="submenu-text">问诊管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/drugordermanagement" @click="saveNavState('/smarthealthcare/drugordermanagement')">
                <template slot="title">
                  <span class="submenu-text">药品订单管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/doctormanagement" @click="saveNavState('/smarthealthcare/doctormanagement')">
                <template slot="title">
                  <span class="submenu-text">医生管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/financialmanagement" @click="saveNavState('/smarthealthcare/financialmanagement')">
                <template slot="title">
                  <span class="submenu-text">财务管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/operationmanagement" @click="saveNavState('/smarthealthcare/operationmanagement')">
                <template slot="title">
                  <span class="submenu-text">运营管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/encountermgmt" @click="saveNavState('/smarthealthcare/encountermgmt')">
                <template slot="title">
                  <span class="submenu-text">就诊人管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/operationrun" @click="saveNavState('/smarthealthcare/operationrun')">
                <template slot="title">
                  <span class="submenu-text">运营管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/pharmacymanagement" @click="saveNavState('/smarthealthcare/pharmacymanagement')">
                <template slot="title">
                  <span class="submenu-text">药房管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/drugmanagement" @click="saveNavState('/smarthealthcare/drugmanagement')">
                <template slot="title">
                  <span class="submenu-text">药品管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/systemmanagement" @click="saveNavState('/smarthealthcare/systemmanagement')">
                <template slot="title">
                  <span class="submenu-text">系统管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <!-- <el-submenu index="ypgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>运营管理</span>
              </template>
              <el-menu-item index="/smarthealthcare/drugrun" @click="saveNavState('/smarthealthcare/drugrun')">
                <template slot="title">
                  <span class="submenu-text">药品管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/diseaserun" @click="saveNavState('/smarthealthcare/diseaserun')">
                <template slot="title">
                  <span class="submenu-text">疾病管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/reciperun" @click="saveNavState('/smarthealthcare/reciperun')">
                <template slot="title">
                  <span class="submenu-text">处方管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/roomrun" @click="saveNavState('/smarthealthcare/roomrun')">
                <template slot="title">
                  <span class="submenu-text">科室管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/doctorrun" @click="saveNavState('/smarthealthcare/doctorrun')">
                <template slot="title">
                  <span class="submenu-text">医生管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/hospitalrun" @click="saveNavState('/smarthealthcare/hospitalrun')">
                <template slot="title">
                  <span class="submenu-text">医院管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/smarthealthcare/listrun" @click="saveNavState('/smarthealthcare/listrun')">
                <template slot="title">
                  <span class="submenu-text">订单管理</span>
                </template>
              </el-menu-item>
            </el-submenu> -->
          </el-menu>
        </div>
        <!-- <div class="uncollapse" @click="isCollapse = !isCollapse">
          <span>收回</span>
          <i class="el-icon-caret-top"></i>
        </div> -->
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activeLbgl: false,
      activePath: '/smarthealthcare'
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('smarthealthcarePath') || '/smarthealthcare'
  },
  methods: {
    handleOpen (key, keyPath) {
      // // console.log(key, keyPath)
      if (key === '/lbgl') {
        this.activeLbgl = true
        this.$router.push('/smarthealthcare/twoDiseaseManagement')
      } else {
        this.activeLbgl = false
      }
    },
    saveNavState (path) {
      console.log(path)
      window.sessionStorage.setItem('headTabActivePath', path)
      window.sessionStorage.setItem('smarthealthcarePath', path)
      this.activePath = path
      this.activeLbgl = false
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    overflow: hidden;
    width: 120px;
    height: 100%;
    background-color: #0E487D;

    .collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      color: #fff;
      height: 100%;
      padding-top: 100px;

      .top {
        height: 30%;
        width: 40px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }

      .bot {
        height: 20%;
        width: 40px;
        margin-top: 80px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .menu {
      position: relative;
      width: 100%;
      height: 100%;

      .uncollapse {
        position: absolute;
        bottom: 0;
        writing-mode: tb-rl;
        text-align: center;
        line-height: 40px;
        margin-left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 100px);

        // scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
        // /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
        // scrollbar-width: thin;
        // /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
        // -ms-overflow-style: none;

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-menu {
          border: none;
        }

        .font {
          font-size: 16px;
        }

        /deep/.el-menu-item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 45px;
          font-size: 16px;
          padding: 0 !important;
        }

        /deep/.el-submenu__title {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 1px !important;
          padding-right: 2px !important;
        }
        /deep/.el-icon-arrow-down:before {
          content: '';
        }
        .menu-text {
          width: 80px;
          line-height: normal;
          white-space: pre-wrap;
        }

        .submenu-text {
          // width: calc(100% - 30px);
          width: 80px;
          margin-left: 30px;
          line-height: normal;
          white-space: pre-wrap;
        }

        .mrgin-left {
          margin-left: 30px;
        }

        .activeLbglColor {
          color: #04B4D0;
        }
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 107px);
    height: 100%;
    margin-left: 7px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
