<template>
  <div class="page">
    <div class="page-left">
      <div v-if="isCollapse" class="collapse">
        <div class="top" @click="isCollapse = !isCollapse">
          <span>展开</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="bot">
          <i class="el-icon-caret-top" @click="pre"></i>
          <i class="el-icon-refresh-right" @click="refresh"></i>
          <i class="el-icon-caret-bottom" @click="next"></i>
        </div>
      </div>
      <div v-if="!isCollapse" class="menu">
        <div class="scroll">
          <el-menu :collapse-transition="false" :default-active="activePath" router background-color="#0E487D"
            text-color="#fff" active-text-color="#04B4D0" unique-opened @open="handleOpen">
            <el-submenu index="ypgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">运营管理</span>
              </template>
              <el-menu-item index="/organrun/drugrun" @click="saveNavState('/organrun/drugrun')">
                <template slot="title">
                  <span class="submenu-text">药品管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/organrun/diseaserun" @click="saveNavState('/organrun/diseaserun')">
                <template slot="title">
                  <span class="submenu-text">疾病管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/organrun/reciperun" @click="saveNavState('/organrun/reciperun')">
                <template slot="title">
                  <span class="submenu-text">处方管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/organrun/roomrun" @click="saveNavState('/organrun/roomrun')">
                <template slot="title">
                  <span class="submenu-text">科室管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/organrun/doctorrun" @click="saveNavState('/organrun/doctorrun')">
                <template slot="title">
                  <span class="submenu-text">医生管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/organrun/hospitalrun" @click="saveNavState('/organrun/hospitalrun')">
                <template slot="title">
                  <span class="submenu-text">医院管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/organrun/listrun" @click="saveNavState('/organrun/listrun')">
                <template slot="title">
                  <span class="submenu-text">订单管理</span>
                </template>
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="/organrun/basicuserinfo" @click="saveNavState('/organrun/basicuserinfo')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">用户基本信息</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/organrun/institutionalusermaintenance" @click="saveNavState('/organrun/institutionalusermaintenance')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">机构用户维护</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/organrun/organlist" @click="saveNavState('/organrun/organlist')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">机构列表</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/organrun/menuroleset" @click="saveNavState('/organrun/menuroleset')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">菜单角色设置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/organrun/systemset" @click="saveNavState('/organrun/systemset')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">系统配置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/organrun/appversioncontrol" @click="saveNavState('/organrun/appversioncontrol')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">APP版本控制</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/organrun/menumanagement" @click="saveNavState('/organrun/menumanagement')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">菜单管理</span>
              </template>
            </el-menu-item>
            <el-submenu index="cxgl">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="font">小程序管理</span>
              </template>
              <el-menu-item index="/organrun/programrun/programmaintenance" @click="saveNavState('/organrun/programrun/programmaintenance')">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span class="font">小程序维护</span>
                </template>
              </el-menu-item>
              <el-submenu index="xcxgl">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span class="font">用户小程序</span>
                </template>
                <el-menu-item index="/organrun/navigationMaintenance" @click="saveNavState('/organrun/navigationMaintenance')">
                  <template slot="title">
                    <span class="submenu-text">导航维护</span>
                  </template>
                </el-menu-item>
                <!-- 三级菜单可继续复制粘贴新增 -->
                <el-menu-item index="/organrun/docterDetil" @click="saveNavState('/organrun/docterDetil')">
                  <template slot="title">
                    <span class="submenu-text">医生简介</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/organrun/rotatingPage" @click="saveNavState('/organrun/rotatingPage')">
                  <template slot="title">
                    <span class="submenu-text">幻灯滚动</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/organrun/consultationInformation" @click="saveNavState('/organrun/consultationInformation')">
                  <template slot="title">
                    <span class="submenu-text">医院介绍</span>
                  </template>
                </el-menu-item>
                <el-menu-item index="/organrun/healthTalking" @click="saveNavState('/organrun/healthTalking')">
                  <template slot="title">
                    <span class="submenu-text">健康讲座</span>
                  </template>
                </el-menu-item>
              </el-submenu>
            </el-submenu>
            <el-menu-item index="/organrun/knowledgebase" @click="saveNavState('/organrun/knowledgebase')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span class="menu-text">知识库维护</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/organrun/healthedu" @click="saveNavState('/organrun/healthedu')">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>健康服务</span>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
        <!-- <div class="uncollapse" @click="isCollapse = !isCollapse">
          <span>收回</span>
          <i class="el-icon-caret-top"></i>
        </div> -->
      </div>
    </div>
    <div class="page-right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      chartDom: null,
      isCollapse: false,
      activeLbgl: false,
      activePath: '/organrun'
    }
  },
  mounted () {
    this.activePath = window.sessionStorage.getItem('organrunath') || '/organrun'
  },
  methods: {
    handleOpen (key, keyPath) {},
    saveNavState (path) {
      window.sessionStorage.setItem('organrunPath', path)
      window.sessionStorage.setItem('headTabActivePath', path)
      this.activePath = path
    },
    pre () { },
    next () { },
    refresh () { }
  }
}
</script>

<style scoped lang="less">
.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;
  font-family: Helvetica leue, Helvetica, pingFang SC, Microsoft YaHei, Arial, san5-serif;

  .page-left {
    overflow: hidden;
    width: 120px;
    height: 100%;
    background-color: #0E487D;

    .collapse {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 120px;
      color: #fff;
      height: 100%;
      padding-top: 100px;

      .top {
        height: 30%;
        width: 40px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }

      .bot {
        height: 20%;
        width: 40px;
        margin-top: 80px;
        writing-mode: tb-rl;
        background-color: #0E487D;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .menu {
      position: relative;
      width: 100%;
      height: 100%;

      .uncollapse {
        position: absolute;
        bottom: 0;
        writing-mode: tb-rl;
        text-align: center;
        line-height: 40px;
        margin-left: 50%;
        margin-top: 20px;
        transform: translateX(-50%);
        cursor: pointer;
        font-size: 20px;
        color: #fff;
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 10px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-menu {
          border: none;
        }

        /deep/.el-menu-item {
          display: flex;
          align-items: center;
          width: 100%;
          height: 45px;
          padding: 0 !important;
          font-size: 16px;
        }

        .font {
          font-size: 16px;
        }

        /deep/.el-submenu__title {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 1px !important;
          padding-right: 2px !important;
        }
        /deep/.el-icon-arrow-down:before {
          content: '';
        }
        .menu-text {
          width: calc(100% - 40px);
          line-height: normal;
          white-space: pre-wrap;
        }

        .submenu-text {
          // width: calc(100% - 30px);
          width: 80px;
          margin-left: 30px;
          line-height: normal;
          white-space: pre-wrap;
        }

        .mrgin-left {
          margin-left: 30px;
        }

        .activeLbglColor {
          color: #04B4D0;
        }
      }
    }
  }

  .page-right {
    overflow-y: scroll;
    width: calc(100% - 107px);
    height: 100%;
    margin-left: 7px;
    padding: 20px;
    background-color: #081C38;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}
</style>
